<template>
  <div class="qup-container flex-1 flex-col center-box container-pd">
    <div class="qup-main flex-1">

      <div class="qup-content ">
        <div
          v-if="isInfo"
          class="flex-col fw-500 mg-b-8">
          <span class="fs-18 mg-b-4">You <span class="color-primary">{{confirmUnderwriting==2?'not confirmed':'confirmed'}}</span> all the underwriting questions below:</span>
          <span class="fs-20">你<span class=" color-primary">{{confirmUnderwriting==2?'不确定':'已確認'}}</span>以下核保問題:</span>
        </div>
        <ul class="qup-list">
          <li
            v-for="(item, index) in underwriting"
            :key="index +'qup-item'"
            class="qup-item">
            <i class="el-icon-check"></i>
            <p v-html="item.name"></p>
            <p v-html="item.name_zh"></p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'underwriting',
  props:{
    isInfo:{
      type:Boolean,
      default:false
    },
    confirmUnderwriting:{
      type:Number,
      default:1
    }
  },
  computed: {
    underwriting() {
      return [
        {
          name:'Confirm that no driver has been convicted of motoring offence that involved deduction of driving offence over <span class="color-red">9 points during the last two years</span>.',
          name_zh:'確認沒有任何一位記名司機於過往<span class="color-red">兩年內被扣超過9分或以上</span>。',
        },
        {
          name:'Confirm that no driver has been involved in a motor accident or <span class="color-red">motor insurance claim during the last three years.</span>',
          name_zh:'確認沒有任何一位記名司機於過往<span class="color-red">三年內牽涉任何交通意外或汽車保險索償。</span>',
        },
        {
          name:'Confirm that no driver has his/ her <span class="color-red">driving licence been suspended or cancelled in the last three years.</span>',
          name_zh:'確認沒有任何一位記名司機於過往<span class="color-red">三年內被停牌或取消駕駛執照</span>。',
        },
        {
          name:'Confirm that no driver has been declined his/ her motor insurance application, or been refused renewal or been terminated such insurance by any insurance company.',
          name_zh:'確認沒有任何一位記名司機被保險公司拒絕投保汽車保險, 終止保單或拒絕提供續保報價。',
        },
        {
          name:'Confirm that no driver has suffered / been suffering from any heart disease, diabetes, defective vision or hearing, mental infirmity or physical disability.',
          name_zh:'確認沒有任何一位記名司機有心臟病，糖尿病，視力不良，聽覺，精神病或身體殘疾。',
        },
        {
          name:'Understand that insurers may have special considerations regarding to the occupation of policyholder and named drivers, and agreed that insurers have the right to adjust the final premium.',
          name_zh:'明白保險公司對投保人及記名司機的職業可能有特殊核保考慮，並同意保險公司擁有最終保費調整的權利。',
        },
        {
          name:'Understand that the premium and terms of new application and renewal application may be different, and agreed that insurers have the right to adjust the final premium.',
          name_zh:'明白保險公司對新投保申請及續保申請的保費有可能不相同，並同意保險公司擁有最終保費調整的權利。',
        },
      ]
    },
  },
}
</script>

<style scoped lang="scss">
.qup-container{
  //width: 770px;
  max-width: 100%;
  background: #F8F8FB;
  border-radius: 12px;
  padding: 24px;
}
.qup-list{
  counter-reset: emNum;
  .qup-item{
    position: relative;
    padding-left: 15px;
    padding-bottom: 10px;
    text-align: left;
    p{
      font-weight: 400;
      font-size: 16px;
      line-height: 175%;
      letter-spacing: 0.02em;
      display: inline-block;
      color: #1A1A1C;
      margin-left: 16px;
      display: block;
    }
    i{
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #003EE5;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
</style>
