<template>
  <section>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <div class="text-left">
        <section
          class="flex-sb-center flex-wrap"
        >
          <div
            v-for="(item,index) in uploadFormList"
            v-show="!item.noshow"
            :key="index"
            class="form-item form-half">
            <template >
              <div class="flex-col text-left">
                <span :content="item.en_content">{{item.en}}</span>
                <span :content="item.zh_content">{{item.zh}}</span>
              </div>
              <el-form-item
                :ref="item.key"
                :show-message="showRuleMessage"
                :rules="isNeedUpload.includes(item.key)||item.noshow?{}:rules.upload"
                :prop="item.key"
              >
                <v-take-photo
                  v-model="formData[item.file]"
                  :otherData="{
                    key:item.key,
                    keyFile:item.file
                  }"
                  card
                  @del="handleDel"
                  @uploadSuccess="handleUploadSuccess"></v-take-photo>
              </el-form-item>
            </template>
          </div>
        </section>
        <section
          v-for="(driver,d_index) in driverList"
          :key="'driver_'+d_index"
          class="dirver-line"
        >
          <div class="flex-col text-left driver-text-title">
            <span>Driver {{d_index+1}}</span>
            <span>司機 {{d_index+1}}</span>
          </div>
          <section class="flex-sb-center flex-wrap">
            <div
              v-if="isOneOfDriver&&d_index!=0||isCompany||!isOneOfDriver"
              class="form-item form-half">
              <div class="flex-col text-left">
                <span>HKID</span>
                <span>香港身份證</span>
              </div>
              <el-form-item
                :ref="`driver.${d_index}.id_file_id`"
                :prop="`driver.${d_index}.id_file_id`"
                :show-message="showRuleMessage"
                :rules="rules.upload"
              >
                <v-take-photo
                  v-model="driver.id_file"
                  :otherData="{
                    key:'id_file_id',
                    keyFile:'id_file',
                    index:d_index,
                    type:'driver'
                  }"
                  card
                  @del="handleDel"
                  @uploadSuccess="handleUploadSuccess"></v-take-photo>
              </el-form-item>
            </div>
            <div class="form-item form-half" >
              <div class="flex-col text-left">
                <span>Driving Licence</span>
                <span>駕駛執照</span>
              </div>
              <el-form-item
                :ref="`driver.${d_index}.driving_licence_file`"
                :show-message="showRuleMessage"
                :rules="rules.driverUpload"
                :prop="`driver.${d_index}.driving_licence_file`"
              >
                <v-take-photo
                  v-model="driver.driving_licence_file"
                  :otherData="{
                    key:'driving_licence_file_id',
                    keyFile:'driving_licence_file',
                    index:d_index,
                    type:'driver'
                  }"
                  card
                  @del="handleDel"
                  @uploadSuccess="handleUploadSuccess"></v-take-photo>
              </el-form-item>
            </div>
            <div
              v-if="check70Years(driver.birth)"
              class="form-item form-half">
              <div class="flex-col text-left">
                <span>Medical Examination Certificate</span>
                <span>醴格檢驗證明書</span>
              </div>
              <el-form-item
                :ref="`driver.${d_index}.mec_file`"
                :show-message="showRuleMessage"
                :rules="rules.driverUpload"
                :prop="`driver.${d_index}.mec_file`"
              >
                <v-take-photo
                  v-model="driver.mec_file"
                  :otherData="{
                    key:'mec_file_id',
                    keyFile:'mec_file',
                    index:d_index,
                    type:'driver'
                  }"
                  card
                  @del="handleDel"
                  @uploadSuccess="handleUploadSuccess"></v-take-photo>
              </el-form-item>
            </div>
            <template v-if="driver.has_accident==2">
              <div
                v-for="(item,a_index) in driver.accidents_file"
                :key="'accidents_file_'+a_index"
                class="form-item form-half">
                <div class="flex-col text-left">
                  <span>Police Statement {{a_index+1}} (optional）</span>
                  <span>口供紙{{a_index+1}} (選填)</span>
                </div>
                <el-form-item
                  :show-message="showRuleMessage"
                >
                  <v-take-photo
                    v-model="driver.accidents_file[a_index]"
                    :otherData="{
                      key:'accidents_file_id',
                      keyFile:'accidents_file',
                      index:d_index,
                      a_index:a_index,
                      type:'driver'
                    }"
                    card
                    @del="handleDel"
                    @uploadSuccess="handleUploadSuccess"></v-take-photo>
                </el-form-item>
              </div>
            </template>
            <div
              v-if="driver.is_dangerous_driving==1"
              class="form-item form-half">
              <div class="flex-col text-left">
                <span>Court Summons (optional）</span>
                <span>法庭信 (選填)</span>
              </div>
              <el-form-item
                :ref="`driver.${d_index}.driving_licence_file_id`"
                :show-message="showRuleMessage"
                :prop="`driver.${d_index}.driving_licence_file_id`"
              >
                <v-take-photo
                  v-model="driver.dangerous_driving_file"
                  :otherData="{
                    key:'dangerous_driving_file_id',
                    keyFile:'dangerous_driving_file',
                    index:d_index,
                    type:'driver'
                  }"
                  card
                  @del="handleDel"
                  @uploadSuccess="handleUploadSuccess"></v-take-photo>
              </el-form-item>
            </div>
            <div
              v-if="driver.has_accident==2||driver.is_dangerous_driving==1"
              class="flex-col text-left upload-text w-fill">
              <span>
                *超過一份請提交櫃檯同事跟進
              </span>
              <span>
                *If there are more than one, please submit it to counter staff for follow-up.
              </span>
            </div>
          </section>
        </section>
        <v-button
          v-if="!isShow"
          @click="handleNext">下一步 Next</v-button>
      </div>
    </el-form>
  </section>

</template>

<script>
import { mapState, mapActions } from 'vuex'
import MixinsPolicyForm from '@/mixins/policyForm'
import MixinsOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
export default {
  name: 'policyholderForm',
  mixins:[MixinsOptions,MixinRules,MixinsPolicyForm],
  props:{
    isShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    const checkUpload = (rule, value, callback) => {
      const index = rule.field.split('.')[1]
      const fieldName = rule.field.split('.')[2]
      let driver = this.formData.driver[index]
      let {file_id} = driver[fieldName]||{}
      if(!file_id) {
        callback(new Error('Please upload 請上傳'))
      }
      callback()
    }
    return {
      nextPath:'/quoting/insure/confirm',
      formData:{
        hkid_file_id:'',
        hkid_file:{},
        br_file_id:'',
        br_file:{},
        ci_file_id:'',
        ci_file:{},
        vrd_file_id:'',
        vrd_file:{},
        ncb_file_id:'',
        ncb_file:{},
        // driving_licence_file_id:'',
        // driving_licence_file:{},
        proof_of_address_file_id:'',
        proof_of_address_file:{},
        driver:[]
      },
      rules: {
        driverUpload: [
          { validator: checkUpload, trigger: ['blur','change'] }
        ],
      },
    }
  },
  computed:{
    isNeedUpload(){
      return ['ncb_file_id','proof_of_address_file_id','br_file_id']
    },
    isHasNcd(){
      let {coverage={},vehicle_detail={}} = this.quoteInformationForm||{}
      let {ncd} = vehicle_detail||{}
      return ncd>0
    },
    isCompany(){
      let {client_type} = this.quoteInformationForm||{}
      return client_type==2
    }
  },
  watch:{
    quoteInformationForm:{
      immediate:true,
      handler(val){

        Object.keys(this.formData).some(key=>{
          if(val&&val[key]){
            this.formData[key] = val[key]
          }
        })
        let {driver} = val||{}
        let arr = driver.reduce((res,item)=>{
          let {accidents_file=[],accidents_file_id=[]} = item||{}
          let len = accidents_file&&accidents_file.length||0
          let len_id = accidents_file_id&&accidents_file_id.length||0
          let new_file = accidents_file||[]
          let new_file_id = accidents_file_id||[]
          if(len<3){
            for(var i=len;i<3;i++)
            {
              new_file.push({url:''})

            }
          }
          if(len_id<3){
            for(var i=len_id;i<3;i++)
            {
              new_file_id.push('')
            }
          }
          res.push({...item,accidents_file:new_file,accidents_file_id:new_file_id})
          return res
        },[])
        this.formData.driver = arr
      }
    },
    isOneOfDriver:{
      immediate:true,
      handler(val){
        let  {hkid_file_id,hkid_file,driver} = this.formData||{}
        if(val){
          this.$nextTick(()=>{
            this.$set(this.formData.driver[0],'id_file',hkid_file)
            this.$set(this.formData.driver[0],'id_file_id',hkid_file_id)
          })
        }
      }
    }
  },
  destroyed() {
    this.setQuoteInformationForm({
      ...this.quoteInformationForm,
      ...this.formData
    })
  },
  methods:{
    submitForm(formName){
      this.$refs.form.validate((valid, rules) => {
        if(valid){
          this.$emit('getFormData', formName, this.formData)
        }else {
          this.scrollToValidDom(rules)
        }
      })
    },
    handleUploadSuccess({data,otherData}){
      let {file_id} = data||{}
      let {key,type,index,a_index,keyFile} = otherData||{}
      if(type=='driver'){
        if(key=='accidents_file_id'){
          this.formData.driver[index][key][a_index] = file_id
          this.formData.driver[index][keyFile][a_index] = data
        }else
        {
          this.formData.driver[index][key] = file_id
          this.$set(this.formData.driver[index],key,file_id)
          this.formData.driver[index][keyFile] = data
          this.$set(this.formData.driver[index],keyFile,data)
          this.$refs.form.clearValidate([`driver.${index}.${keyFile}`])
        }
        if(key=='id_file_id'){
          this.$refs.form.clearValidate([`driver.${index}.id_file_id`])
        }
      }else
      {
        this.formData[key]=file_id
        this.$refs.form.clearValidate([key])
      }
      if(key=='id_file_id'&&this.isOneOfDriver)
      {
        let  {hkid_file_id,hkid_file,driver} = this.formData||{}
        this.$set(this.formData.driver[0],'id_file',hkid_file)
        this.$set(this.formData.driver[0],'id_file_id',hkid_file_id)

      }
      if(this.isShow){
        this.setQuoteInformationForm({
          ...this.quoteInformationForm,
          ...this.formData
        })
      }
    },
    handleDel({otherData}){
      let {key,type,index,a_index,keyFile} = otherData||{}
      if(type=='driver')
      {
        if(key=='accidents_file_id'){
          this.formData.driver[index][key][a_index] = ''
          this.formData.driver[index][keyFile][a_index] = null
        }else {
          this.formData.driver[index][key]=''
          this.formData.driver[index][keyFile]=null
        }
      }else
      {
        this.formData[key]=''
        this.formData[keyFile]=null
      }
    },
  }
}
</script>

<style scoped lang="scss">
.address{
  .form-half{
    width: calc(50% - 16px);
    margin-right: 16px;
    &:last-child{
      margin-right: 0;
    }
  }
}
.dirver-line{
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid #B4B4B7;
  .driver-text-title{
    margin-bottom: 25px;
    span{
      color: #1A1A1C;
      font-feature-settings: 'pwid' on;
      /* H3 */
      font-family: Noto Sans HK;
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 39px */
      letter-spacing: 0.52px;
      &:first-child{
        font-size: 20px;
        letter-spacing: 0.4px;
      }
    }
  }
}
.upload-text{
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  letter-spacing: 0.24px;
}
</style>
