var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules}},[_c('div',{staticClass:"text-left"},[_c('section',{staticClass:"flex-sb-center flex-wrap"},_vm._l((_vm.uploadFormList),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!item.noshow),expression:"!item.noshow"}],key:index,staticClass:"form-item form-half"},[[_c('div',{staticClass:"flex-col text-left"},[_c('span',{attrs:{"content":item.en_content}},[_vm._v(_vm._s(item.en))]),_c('span',{attrs:{"content":item.zh_content}},[_vm._v(_vm._s(item.zh))])]),_c('el-form-item',{ref:item.key,refInFor:true,attrs:{"show-message":_vm.showRuleMessage,"rules":_vm.isNeedUpload.includes(item.key)||item.noshow?{}:_vm.rules.upload,"prop":item.key}},[_c('v-take-photo',{attrs:{"otherData":{
                  key:item.key,
                  keyFile:item.file
                },"card":""},on:{"del":_vm.handleDel,"uploadSuccess":_vm.handleUploadSuccess},model:{value:(_vm.formData[item.file]),callback:function ($$v) {_vm.$set(_vm.formData, item.file, $$v)},expression:"formData[item.file]"}})],1)]],2)}),0),_vm._l((_vm.driverList),function(driver,d_index){return _c('section',{key:'driver_'+d_index,staticClass:"dirver-line"},[_c('div',{staticClass:"flex-col text-left driver-text-title"},[_c('span',[_vm._v("Driver "+_vm._s(d_index+1))]),_c('span',[_vm._v("司機 "+_vm._s(d_index+1))])]),_c('section',{staticClass:"flex-sb-center flex-wrap"},[(_vm.isOneOfDriver&&d_index!=0||_vm.isCompany||!_vm.isOneOfDriver)?_c('div',{staticClass:"form-item form-half"},[_c('div',{staticClass:"flex-col text-left"},[_c('span',[_vm._v("HKID")]),_c('span',[_vm._v("香港身份證")])]),_c('el-form-item',{ref:`driver.${d_index}.id_file_id`,refInFor:true,attrs:{"prop":`driver.${d_index}.id_file_id`,"show-message":_vm.showRuleMessage,"rules":_vm.rules.upload}},[_c('v-take-photo',{attrs:{"otherData":{
                  key:'id_file_id',
                  keyFile:'id_file',
                  index:d_index,
                  type:'driver'
                },"card":""},on:{"del":_vm.handleDel,"uploadSuccess":_vm.handleUploadSuccess},model:{value:(driver.id_file),callback:function ($$v) {_vm.$set(driver, "id_file", $$v)},expression:"driver.id_file"}})],1)],1):_vm._e(),_c('div',{staticClass:"form-item form-half"},[_c('div',{staticClass:"flex-col text-left"},[_c('span',[_vm._v("Driving Licence")]),_c('span',[_vm._v("駕駛執照")])]),_c('el-form-item',{ref:`driver.${d_index}.driving_licence_file`,refInFor:true,attrs:{"show-message":_vm.showRuleMessage,"rules":_vm.rules.driverUpload,"prop":`driver.${d_index}.driving_licence_file`}},[_c('v-take-photo',{attrs:{"otherData":{
                  key:'driving_licence_file_id',
                  keyFile:'driving_licence_file',
                  index:d_index,
                  type:'driver'
                },"card":""},on:{"del":_vm.handleDel,"uploadSuccess":_vm.handleUploadSuccess},model:{value:(driver.driving_licence_file),callback:function ($$v) {_vm.$set(driver, "driving_licence_file", $$v)},expression:"driver.driving_licence_file"}})],1)],1),(_vm.check70Years(driver.birth))?_c('div',{staticClass:"form-item form-half"},[_c('div',{staticClass:"flex-col text-left"},[_c('span',[_vm._v("Medical Examination Certificate")]),_c('span',[_vm._v("醴格檢驗證明書")])]),_c('el-form-item',{ref:`driver.${d_index}.mec_file`,refInFor:true,attrs:{"show-message":_vm.showRuleMessage,"rules":_vm.rules.driverUpload,"prop":`driver.${d_index}.mec_file`}},[_c('v-take-photo',{attrs:{"otherData":{
                  key:'mec_file_id',
                  keyFile:'mec_file',
                  index:d_index,
                  type:'driver'
                },"card":""},on:{"del":_vm.handleDel,"uploadSuccess":_vm.handleUploadSuccess},model:{value:(driver.mec_file),callback:function ($$v) {_vm.$set(driver, "mec_file", $$v)},expression:"driver.mec_file"}})],1)],1):_vm._e(),(driver.has_accident==2)?_vm._l((driver.accidents_file),function(item,a_index){return _c('div',{key:'accidents_file_'+a_index,staticClass:"form-item form-half"},[_c('div',{staticClass:"flex-col text-left"},[_c('span',[_vm._v("Police Statement "+_vm._s(a_index+1)+" (optional）")]),_c('span',[_vm._v("口供紙"+_vm._s(a_index+1)+" (選填)")])]),_c('el-form-item',{attrs:{"show-message":_vm.showRuleMessage}},[_c('v-take-photo',{attrs:{"otherData":{
                    key:'accidents_file_id',
                    keyFile:'accidents_file',
                    index:d_index,
                    a_index:a_index,
                    type:'driver'
                  },"card":""},on:{"del":_vm.handleDel,"uploadSuccess":_vm.handleUploadSuccess},model:{value:(driver.accidents_file[a_index]),callback:function ($$v) {_vm.$set(driver.accidents_file, a_index, $$v)},expression:"driver.accidents_file[a_index]"}})],1)],1)}):_vm._e(),(driver.is_dangerous_driving==1)?_c('div',{staticClass:"form-item form-half"},[_c('div',{staticClass:"flex-col text-left"},[_c('span',[_vm._v("Court Summons (optional）")]),_c('span',[_vm._v("法庭信 (選填)")])]),_c('el-form-item',{ref:`driver.${d_index}.driving_licence_file_id`,refInFor:true,attrs:{"show-message":_vm.showRuleMessage,"prop":`driver.${d_index}.driving_licence_file_id`}},[_c('v-take-photo',{attrs:{"otherData":{
                  key:'dangerous_driving_file_id',
                  keyFile:'dangerous_driving_file',
                  index:d_index,
                  type:'driver'
                },"card":""},on:{"del":_vm.handleDel,"uploadSuccess":_vm.handleUploadSuccess},model:{value:(driver.dangerous_driving_file),callback:function ($$v) {_vm.$set(driver, "dangerous_driving_file", $$v)},expression:"driver.dangerous_driving_file"}})],1)],1):_vm._e(),(driver.has_accident==2||driver.is_dangerous_driving==1)?_c('div',{staticClass:"flex-col text-left upload-text w-fill"},[_c('span',[_vm._v(" *超過一份請提交櫃檯同事跟進 ")]),_c('span',[_vm._v(" *If there are more than one, please submit it to counter staff for follow-up. ")])]):_vm._e()],2)])}),(!_vm.isShow)?_c('v-button',{on:{"click":_vm.handleNext}},[_vm._v("下一步 Next")]):_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }