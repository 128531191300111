<template >
  <section class="text-left confirm-page container-pd__100">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <!--      <underwriting-->
      <!--        :confirm-underwriting="formData.confirm_underwriting"-->
      <!--        is-info></underwriting>-->
      <div class="flex flex-table">
        <div class="width-half">
          <div
            v-for="(item,index) in infoListData(0,2)"
            :key="index"
            class="table">
            <div class="th">
              <span class="flex-col">
                <span>{{item.title_en}}</span>
                <span>{{item.title_zh}}</span>
              </span>
              <span
                class="color-primary hand"
                @click="handleEdit(item.path)">Edit 更改</span>
            </div>
            <div
              v-for="(name,LIndex) in item.list"
              v-show="!name.noshow"
              :key="'table-'+index+LIndex"
              class="tr">
              <span class="flex-col">
                <span>{{ name.en }}</span>
                <span>{{ name.zh }}</span>
              </span>
              <span>{{name.val||'-'}}</span>
            </div>
            <div
              v-if="index==1"
              class="tr">
              <span class="flex-col">
                <span>Correspondence Address</span>
                <span>通訊地址</span>
              </span>
              <span>{{address}}</span>
            </div>
            <div
              v-if="index==1&&isCompany"
              class="tr">
              <span class="flex-col">
                <span>Contact Person</span>
                <span>聯絡人</span>
              </span>
              <span>
                {{formData.holder_contact_name}}<br>
                {{formData.holder_contact_phone}}
              </span>
            </div>
          </div>
        </div>
        <div class="width-half">
          <div
            v-for="(item,index) in infoListData(1,3)"
            :key="index"
            class="table">
            <div class="th">
              <span class="flex-col">
                <span>{{item.title_en}}</span>
                <span>{{item.title_zh}}</span>
              </span>
              <span
                class="color-primary hand"
                @click="handleEdit(item.path)">Edit 更改</span>
            </div>
            <div
              v-for="(name,LIndex) in item.list"
              v-show="!name.noshow"
              :key="'table-'+index+LIndex"
              class="tr">
              <span class="flex-col">
                <span>{{ name.en }}</span>
                <span>{{ name.zh }}</span>
              </span>
              <span>{{name.val||'-'}}</span>
            </div>
            <div
              v-if="index==2"
              class="tr">
              <span class="flex-col">
                <span>Correspondence Address</span>
                <span>通訊地址</span>
              </span>
              <span>{{address}}</span>
            </div>
            <div
              v-if="index==2&&isCompany"
              class="tr">
              <span class="flex-col">
                <span>Contact Person</span>
                <span>聯絡人</span>
              </span>
              <span>
                {{formData.holder_contact_name}}<br>
                {{formData.holder_contact_phone}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-table mg-b-32">
        <div
          v-for="(driver,index) in driverList"
          :key="'driver_info_'+index"
          class="table"
          :class="{'pd-b-25':driverList.length>=2&&index==0}">
          <div class="th">
            <span class="flex-col">
              <span>Driver {{index+1}}</span>
              <span>司機 {{index+1}}</span>
            </span>
            <span
              class="color-primary hand"
              @click="handleEdit('/quoting/insure/driver')">Edit 更改</span>
          </div>
          <div
            class="tr">
            <span class="flex-col">
              <span>Relationship with Policyholder</span>
              <span>與投保人關係</span>
            </span>
            <span>{{getOptionName('globalOptions.relationship',driver.relationship_id)}}</span>
          </div>
          <div
            class="tr">
            <span class="flex-col">
              <span>Title</span>
              <span>稱謂</span>
            </span>
            <span>{{getOptionName('titleOption',driver.title)}}</span>
          </div>
          <div
            class="tr">
            <span class="flex-col">
              <span>Last Name</span>
              <span>姓氏</span>
            </span>
            <span>{{driver.last_name}}</span>
          </div>
          <div
            class="tr">
            <span class="flex-col">
              <span>First Name</span>
              <span>名字</span>
            </span>
            <span>{{driver.first_name}}</span>
          </div>
          <div
            class="tr">
            <span class="flex-col">
              <span>Date of Birth</span>
              <span>出生日期</span>
            </span>
            <span>{{driver.birth}}</span>
          </div>
          <div
            class="tr">
            <span class="flex-col">
              <span>{{driver.id_type==1?'HKID Number':'Passport Number'}}</span>
              <span>{{driver.id_type==1?'香港身份證號碼':'護照號碼'}}</span>
            </span>
            <span>{{driver.id_type==1?driver.id_no:driver.pp_no}}</span>
          </div>
          <div
            class="tr">
            <span class="flex-col">
              <span>Occupation</span>
              <span>職業</span>
            </span>
            <span>{{getOptionName('globalOptions.occ',driver.occ_id)}} <span v-if="driver.occ_id==70">{{driver.occ}}</span></span>
          </div>
          <div
            class="tr">
            <span class="flex-col">
              <span>Type of Motorcycle Driving licence</span>
              <span>電單車駕駛執照類型</span>
            </span>
            <span>{{getOptionName('globalOptions.driving_licence',driver.driving_licence_id)}}</span>
          </div>
          <div
            v-if="driver.driving_licence_id==3"
            class="tr">
            <span class="flex-col">
              <span>Year of driving Experience</span>
              <span>駕駛年數</span>
            </span>
            <span>{{getDriverExp(driver.driver_experience_id)}}</span>
          </div>
          <div
            class="tr">
            <span class="flex-col">
              <span>Number of driving offence point in last 3 years (Excludes traffic accidents)</span>
              <span>過往3年內被扣分數 (不包括交通意外)</span>
            </span>
            <span>{{getOptionName('globalOptions.deducted_points',driver.deducted_points)}}</span>
          </div>
          <div
            class="tr">
            <span class="flex-col">
              <span>Reason</span>
              <span>原因</span>
            </span>
            <!--            <span>-->
            <!--              <span>{{getArrOptionName('globalOptions.deducted_point_reason',driver.deducted_point_reason_id)}}</span>-->
            <!--              <span v-if="driver.deducted_point_reason_id&&driver.deducted_point_reason_id.includes(4)">: {{driver.deducted_point_reason}}</span>-->
            <!--            </span>-->
            <span>
              <p
                v-for="(dec_item,acc_index) in driver.deducted_point_reason_id"
                :key="'dec_'+acc_index">{{getOptionName('globalOptions.deducted_point_reason',dec_item)}} <span v-if="dec_item==4">: {{driver.deducted_point_reason}}</span></p>
            </span>
          </div>
          <div
            class="tr">
            <span class="flex-col">
              <span>Have any been involved in any car accident or motor claim in the past 3 years?</span>
              <span>是否最近三年曾發生意外、賠償或停牌？</span>
            </span>
            <span>{{getOptionName('yesOr2NoOption',driver.has_accident)}}</span>
          </div>
          <div
            v-if="driver.has_accident==2"
            class="tr">
            <span class="flex-col">
              <span>Reason</span>
              <span>原因</span>
            </span>
            <span>
              <p
                v-for="(acc_item,acc_index) in driver.accident_reason_id"
                :key="'acc_'+acc_index">{{getOptionName('globalOptions.accident_reason',acc_item)}} <span v-if="acc_item==4">: {{driver.accident_reason}}</span></p>
            </span>
          </div>

          <div
            class="tr">
            <span class="flex-col">
              <span>Have any been declined motor insurance, had a motor insurance policy cancelled?</span>
              <span>是否曾經被保險公司拒絕汽車保險續期或被取消汽車保險？</span>
            </span>
            <span>{{getOptionName('yesOrNoOption',driver.is_declined)}}</span>
          </div>
          <div
            v-if="driver.is_declined==1"
            class="tr">
            <span class="flex-col">
              <span>Reason</span>
              <span>原因</span>
            </span>
            <span>{{driver.declined_reason}}</span>
          </div>
          <div
            class="tr">
            <span class="flex-col">
              <span>Have any suffered from any physical or mental infirmity that may affect his/her ability to drive?</span>
              <span>是否健康或精神上影響閣下之駕駛能力？</span>
            </span>
            <span>{{getOptionName('yesOrNoOption',driver.is_no_driving_ability)}}</span>
          </div>
          <div
            v-if="driver.is_no_driving_ability==1"
            class="tr">
            <span class="flex-col">
              <span>Reason</span>
              <span>原因</span>
            </span>
            <span>{{driver.no_driving_ability_reason}}</span>
          </div>
          <div
            class="tr">
            <span class="flex-col">
              <span>Have been prosecuted by police, Dangerous Driving or driving licence suspended or disqualified by court ?</span>
              <span>是否曾涉及不小心或魯莽駕駛、酒後或藥後駕駛、被吊銷執照或被記錄違例駕駛？</span>
            </span>
            <span>{{getOptionName('yesOrNoOption',driver.is_dangerous_driving)}}</span>
          </div>
          <div
            v-if="driver.is_dangerous_driving==1"
            class="tr">
            <span class="flex-col">
              <span>Reason</span>
              <span>原因</span>
            </span>
            <span>{{driver.dangerous_driving_reason}}</span>
          </div>
        </div>
      </div>
      <upload-form
        ref="upload-form"
        is-show
        @getFormData="getSubFormData"></upload-form>
      <div class="w-fill footer">
        <v-button
          @click="handleConfirm">確認 Confirm</v-button>
      </div>
    <!--      </div>-->
    </el-form>
  </section>
</template>

<script>
import underwriting from '@/views/quoting/car/components/underwriting'
import { mapState, mapActions } from 'vuex'
import MixinsOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import MixinsPolicyForm from '@/mixins/policyForm'
import uploadForm from './upload-form'
export default {
  name: 'confirmInfo',
  components: {uploadForm},
  mixins:[MixinsOptions,MixinRules,MixinsPolicyForm],
  data() {
    return {
      nextPath:'/quoting/insure/signature',
      confirm:'',
      formData:{},
      subForm: {
        'upload-form':false,
      },
    }
  },
  computed: {
    dirverExpList(){
      let {class_id=1003} = this.quoteInformationForm||{}
      let {driving_experience} = this.globalOptions||{}
      let arr = driving_experience.filter(item=>item.class_id==class_id)
      return arr.length>0?arr[0].item||[]:[]
    },
    infoListData(){
      return (index1,index2)=>{
        return this.infoList.filter((item,index)=>[index1,index2].includes(index))
      }
    },
    address(){
      let {correspondence_address} = this.formData||{}
      let {room,floor,block,building,street,district,area} = correspondence_address||{}
      let room_str=room?`ROOM ${room} `:''
      let floor_str=floor?`${floor}/F `:''
      let block_str=block?`BLOCK ${block} `:''
      let building_str = building?`${building},`:''
      let street_str = street?`${street},`:''
      let district_str = this.getAreaName('district',district)
      let area_str = this.getAreaName('area',area)
      let str = room_str+floor_str+block_str+building_str+street_str
      return str+`${district_str}, ${area_str}`
    },
    isCompany(){
      let {client_type} = this.quoteInformationForm||{}
      return client_type==2
    }
  },
  watch:{
    quoteInformationForm:{
      immediate:true,
      handler(val){
        this.formData = {
          ...this.formData,
          ...val
        }
        if(!val){
          this.$router.replace('/')
        }
      }
    }
  },
  methods: {
    getSubFormData(form, data) {
      this.subForm[form] = true
      this.formData = Object.assign(this.formData, data)
    },
    getDriverExp(id){
      let arr = this.dirverExpList.filter(item=>item.id==id)||[]
      return arr.length>0?arr[0].name||'':''
    },
    handleConfirm(){
      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      this.setQuoteInformationForm({
        ...this.quoteInformationForm,
        ...this.formData,
      })
      this.$router.push('/quoting/insure/signature')
    },
    handleEdit(path){
      this.$router.push(path)
    },
    network(){
      return {
        saveQuotation: async (params) => {

        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.confirm-page{
  .table:last-child{
    padding-bottom: 100px;
  }
  .flex-table{
    flex-wrap: wrap;
    .width-half{
      flex: 1;
      &:first-child{
        margin-right: 32px;
      }
      .table{
        margin-bottom: 32px;
      }
    }
    .table{
      border: 0px;
      margin: 0;
      margin-top: 0!important;
      padding: 0;
      .th{
        .flex-col{
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;

          letter-spacing: 0.02em;
          font-feature-settings: 'pwid' on;

          color: #757578;
        }
        border-bottom: 1px solid #B4B4B7;
        padding-bottom: 8px;
      }
      .tr{
       span{
         font-size: 12px!important;
       }
      }
    }
  }
  .pd-b-25{
    padding-bottom: 32px!important;
  }
}

  .footer{
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 32px;
    background: #FFFFFF;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.08);
    margin-left: -32px;
    display: flex;
    justify-content: center;
  }
</style>
